import React, { useState } from 'react';
import Stereo from '../images/stereo.png'; 
import Logo from "../images/logo/Quick-Care.png";
import { Link, useNavigate } from 'react-router-dom';
import { setToken, setUsername } from '../utils/tokenHelper';  // Import the helper functions

const Login = () => {
    const [username, setUsernameState] = useState('254');  // Change phone_number to username
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false); // Add loading state
    const navigate = useNavigate();

    const handleUsernameChange = (e) => {  // Change handlePhoneNumberChange to handleUsernameChange
        const input = e.target.value;
        // Ensure the phone number always starts with '234'
        if (input.startsWith('254')) {
            setUsernameState(input);
        } else if (!input) {
            setUsernameState('254'); // Reset to '234' if the input is cleared
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        if (!username || username.length < 4 || username.slice(0, 3) !== '254') {
            setMessage('Phone number must start with "254" and cannot be empty.');
            setIsError(true);
            return;
        }
    
        if (!password) {
            setMessage('Password cannot be empty.');
            setIsError(true);
            return;
        }
    
        const loginData = {
            username: username,  // Ensure this matches the expected field name
            password: password,
        };

        const urlEncodedData = new URLSearchParams(loginData).toString();
    
        try {
            setIsLoading(true);  // Start loading
            const response = await fetch('https://api.quickcare.ng/api/v1/user/login', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: urlEncodedData
            });
    
            if (response.ok) {
                const result = await response.json();
                setToken(result.access_token);  // Store the token
                setUsername(username);  // Store the username
                console.log(`Saved username: ${username}`);  // Log the saved username
                setMessage('Login successful!');
                setIsError(false);
                setTimeout(() => {
                    navigate('/health');  // Navigate to another page
                }, 2000);  // Wait for 2 seconds before navigating
            } else {
                const errorResponse = await response.json();  // Parse JSON response to get the error message
                if (errorResponse.detail && Array.isArray(errorResponse.detail)) {
                    const errorMessages = errorResponse.detail.map((err) => err.msg).join(', ');
                    setMessage(`Login failed: ${errorMessages}`);
                } else {
                    setMessage('Login failed: Invalid email or password.');
                }
                setIsError(true);
            }
        } catch (error) {
            setMessage('An error occurred. Please try again.');
            setIsError(true);
        } finally {
            setIsLoading(false);  // Stop loading
        }
    };

    return (
        <div className='w-full md:flex flex-row h-screen'>
            <div className='w-full flex flex-col gap-4 p-7 md:w-1/2 md:items-start md:justify-center md:gap-16 lg:gap-8'>
                <Link to='/'>
                    <img src={Logo} alt="" className='w-[150px] h-auto' />
                </Link>

                <div className='mt-4 flex w-full flex-col gap-2'>
                    <h1 className='text-2xl font-semibold'>Login</h1>

                    <div className='flex flex-row gap-6'>
                        <p>New to QuickCare?</p>
                        <Link to='/subscription' className='text-[#45B139]'>Create Account</Link>
                    </div>
                </div>

                {message && (
                    <div className={`mt-4 p-2 text-center ${isError ? 'text-red-500' : 'text-green-500'}`}>
                        {message}
                    </div>
                )}

                <form onSubmit={handleSubmit} className='flex flex-col w-full gap-4 items-center md:gap-8'>
                    <div className='w-full flex flex-col gap-1'>
                        <input
                            type="tel"
                            value={username}  // Change phone_number to username
                            onChange={handleUsernameChange}  // Change handlePhoneNumberChange to handleUsernameChange
                            className='w-full border-b border-[#C4C4C4] px-1 py-1 outline-0 text-[#777777]'
                            placeholder='Phone Number'
                        />
                    </div>

                    <div className='w-full flex flex-col gap-1'>
                        <input
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className='w-full border-b border-[#C4C4C4] px-1 py-1 outline-0 text-[#777777]'
                            placeholder='Password'
                        />
                    </div>

                    <div className='flex items-center w-full justify-between mt-2'>
                        <div className='flex items-center gap-2'>
                            <input type="checkbox" className='w-5 h-5' />
                            <p className='text-sm'>Remember me</p>
                        </div>

                        <Link to='/forgot-password' className='text-sm'>
                            Forgot Password
                        </Link>
                    </div>

                    <button 
                        type='submit' 
                        className='w-full flex items-center justify-center p-2 rounded bg-[#069125] font-medium text-white tracking-wider'
                        disabled={isLoading}  // Disable button when loading
                    >
                        {isLoading ? (
                            <div className="loader">
                                Loading ....
                            </div>  // Display loader
                        ) : (
                            'Login'
                        )}
                    </button>
                </form>
            </div>

            <img src={Stereo} alt="" className='hidden md:flex w-1/2' />
        </div>
    );
};

export default Login;
